module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jetana","short_name":"Jetana","description":"Jetana Oakridge Concession Store","lang":"en","start_url":"/","background_color":"#E43F28","theme_color":"#E43F28","display":"standalone","icon":"src/images/logo/jetana-logo-256.png","icons":[{"src":"src/images/logo/jetana-logo-192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/logo/jetana-logo-512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"550375a538e78f581d1808e2959a868a"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
